export const AdminSearch = () => (
  <div className="w-full lg:w-[750px]">
    <input
      disabled
      type="text"
      placeholder="Disabled: Search using name, email, or property address"
      className="w-full px-[16px] py-[8px] rounded-lg border border-brand-heavy-warmth-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
    />
  </div>
);
