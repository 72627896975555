import { Outlet } from "react-router-dom";

import { AdminProtectedRoute } from "../../../components/ProtectedRoute/AdminProtectedRoute";
import { AdminNavigation } from ".";

export const AdminPageTemplate = () => (
  <AdminProtectedRoute>
    <div className="w-full min-h-full pt-[125px] lg:pt-[85px]">
      <AdminNavigation />

      <div className="w-full flex-grow px-[8px] md:px-[16px] mb-[160px]">
        <Outlet />
      </div>
    </div>
  </AdminProtectedRoute>
);
