import { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Typography, Icons } from "@pongo";

import { AdminSearch, AdminNavigationLabel, navigationItems } from ".";
import { useAuthStore } from "../../../store/auth/auth";
import { AuthContext } from "../../../store/auth/AuthContext";
import { handleSignOut } from "@utils";

import logoSrc from "@assets/logos/small-logo.svg";

const ADMIN_HOME_URL = "/admin";
const CONFIG_URL = "/admin/config";
const WELCOME_BACK_URL = "/welcome-back";

export const AdminNavigation = () => (
  <div className="z-50 fixed top-0 left-0 right-0 w-full bg-brand-heavy-warmth-100 border-b border-brand-heavy-warmth-100 px-[8px] lg:px-[16px]">
    <div className="w-full flex justify-between items-center py-[8px]">
      <div className="flex">
        <Logo />
        <LeftNavItems />
      </div>

      <div className="hidden lg:block">
        <AdminSearch />
      </div>

      <RightNavItems />
    </div>

    <div className="lg:hidden mb-[8px]">
      <AdminSearch />
    </div>
  </div>
);

const LeftNavItems = () => (
  <div className="flex items-center mr-[8px]">
    {navigationItems.map(({ label, path, icon }) => (
      <div
        key={label}
        className={`relative p-[8px] rounded-lg ${location.pathname === path && "bg-brand-heavy-teal-5/50"}`}
      >
        <Link to={path}>
          <div className="flex items-center group relative">
            <Icons
              iconName={icon as keyof typeof Icons}
              size="18"
              colour="#40656D"
            />

            <AdminNavigationLabel label={label} />

            <Typography
              variant="sm"
              weight="semi-bold"
              className={`hidden text-brand-heavy-teal-100`}
            >
              {label}
            </Typography>
          </div>
        </Link>
      </div>
    ))}
  </div>
);

const RightNavItems = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const { userData, actions: authStoreActions } = useAuthStore();
  const authStoreContext = useContext(AuthContext);

  useEffect(() => {
    if (userData?.active && userData?.email) {
      setIsSignedIn(true);
    }
  }, [userData, isSignedIn]);

  return (
    <div className="flex justify-end items-center">
      <div className="hidden lg:inline-block px-[8px]">
        <Link to={WELCOME_BACK_URL}>
          <Typography
            type="p"
            variant="sm"
            weight="regular"
            className={`text-brand-heavy-teal-100/50 ml-[12px] text-nowrap overflow-clip`}
          >
            {userData?.email}
          </Typography>
        </Link>
      </div>

      <div
        className={`relative p-[8px] rounded-lg ${location.pathname === "/admin/config" && "bg-brand-heavy-teal-5/50"}`}
      >
        <Link to={CONFIG_URL}>
          <div className="flex items-center group relative">
            <Icons iconName="Settings" size="18" colour="#40656D" />

            <AdminNavigationLabel label="Config" />

            <Typography
              variant="sm"
              weight="semi-bold"
              className="hidden pl-[16px]"
            >
              Config
            </Typography>
          </div>
        </Link>
      </div>

      <div className="flex items-center relative px-[8px] rounded-lg">
        <button
          onClick={() =>
            handleSignOut(navigate, authStoreActions, authStoreContext)
          }
        >
          <div className="flex items-center group relative">
            <div className="transform rotate-180">
              <Icons iconName="SignOut" size="18" colour="#40656D" />
            </div>

            <AdminNavigationLabel label="Sign Out" />

            <Typography
              variant="sm"
              weight="semi-bold"
              className="hidden pl-[16px]"
            >
              Sign Out
            </Typography>
          </div>
        </button>
      </div>
    </div>
  );
};

const Logo = () => (
  <Link
    to={ADMIN_HOME_URL}
    className="flex items-center justify-center bg-brand-electric-100 rounded-lg h-[34px] w-[34px] shrink-0 mr-[8px]"
  >
    <div className="flex items-center group relative">
      <div className="relative left-[-1px]">
        <img src={logoSrc} width={18} />
      </div>

      <AdminNavigationLabel label="Home" />
    </div>
  </Link>
);
